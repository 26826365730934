.logo-erro {
    width: 45%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.container-erro {
    background-color: #373736;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
}

.container-erro-items {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container-erro-label {
    color: #ccc;
    font-weight: bold;
    font-size: 1.5em;
}