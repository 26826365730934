@font-face {
	font-family: Roboto-Regular;
	src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: Roboto-Bold;
	src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
	font-family: museo;
	src: url('./fonts/MuseoSansCyrl.woff.ttf');
}
@font-face {
	font-family: museo700;
	src: url('./fonts/museosanscyrl-700.ttf');
}

@font-face {
	font-family: museo900;
	src: url('./fonts/Museo900-Regular.otf');
}

body {
	margin: 0;
	font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	background: #242324;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	font-family: Roboto-Regular;
	cursor: pointer;
}

@keyframes openBoleto {
	0% {
		opacity: 0.6;
		transform: translateY(10%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}
