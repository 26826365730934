.container-tela-pdf {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-around;
	align-items: center;
}

.tela-pdf-logo {
	display: flex;
	justify-content: center;
}

.tela-pdf-logo img {
	width: 15%;
	min-width: 250px;
}

.tela-pdf-iframe {
	display: flex;
  justify-content: center;
  background: gray;
  min-width: 70%;
}

.tela-pdf-iframe iframe{
  border: none;
  outline: none;
  min-height: 65vh;
  width: 100%;
}

.tela-pdf-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tela-pdf-button button {
	text-align: center;
	background-color: #FFD32A;
  width: 250px;
  border: 57px;
	text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
	border: none;
	outline: none;
	color: white;
  border-radius: 10px;
  font-family: museo700;
}

@media only screen and (max-width: 720px) and (orientation: landscape) {

  .tela-pdf-iframe {
    width: 90%;
  }

  .tela-pdf-iframe iframe{
    height: 300px;
  }

  .tela-pdf-logo {
    margin: 25px;
  }

  .tela-pdf-button button {
    margin: 25px;
  }
}
@media only screen and (max-width: 720px) and (orientation: portrait) {

  .tela-pdf-iframe {
    width: 90%;
  }

}

